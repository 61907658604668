import $api from '@/helpers/config-axios'

import authHeader from './auth-header'
import store from '@/store'
import {eventBus} from '@/main'
import {errorHandler} from '@/assets/scripts/scripts'

class RolesService {
  getRoles() {
    const {orderBy, sortedBy, filter, search, paginationPage, isArchive} = store.state.roles

    store.commit('other/onChangeLoadingItems', true)

    let newParams = {
      page: paginationPage,
      // include: 'schemaPermissions',
    }

    if (orderBy !== null) newParams['orderBy'] = orderBy
    if (sortedBy !== null) newParams['sortedBy'] = sortedBy
    if (filter !== null) newParams['filter'] = filter.main
    if (search !== '') newParams['search'] = search

    if (isArchive) newParams['only-trashed'] = `1`

    return $api
      .get('roles', {
        params: newParams,
        headers: authHeader(),
      })
      .then(r => {
        store.commit('other/onChangeLoadingItems', false)
        return r
      })
      .catch(e => {
        errorHandler(e)
        return e
      })
  }

  getRole(id) {
    return $api
      .get('roles/' + id, {
        params: {
          include: 'schemaPermissions',
        },
        headers: authHeader(),
      })
      .catch(e => {
        errorHandler(e)
        return e
      })
  }

  getRolesList() {
    return $api
      .get('roles?to=list', {
        headers: authHeader(),
      })
      .catch(e => {
        errorHandler(e)
        return e
      })
  }

  getRolesForHandler() {
    return $api
      .get('roles', {
        params: {
          include: 'schemaPermissions',
        },
        headers: authHeader(),
      })
      .catch(e => {
        errorHandler(e)
        return e
      })
  }

  updateRole(id, data) {
    return $api
      .patch('roles/' + id, data, {
        headers: authHeader(),
      })
      .catch(e => {
        errorHandler(e)
        return e
      })
  }

  createRole(data) {
    return $api
      .post('roles', data, {
        headers: authHeader(),
      })
      .catch(e => {
        errorHandler(e)
      })
  }

  archiveRestoreRoles() {
    return $api
      .post(
        'restore/roles',
        {
          ids: store.state.roles.selectedItemsForDelete,
        },
        {
          headers: authHeader(),
        }
      )
      .catch(e => {
        errorHandler(e)
        return e
      })
  }

  deleteRole(permanent = false) {
    let newParams = {}
    if (permanent) newParams['force-delete'] = 1

    return $api
      .delete('roles', {
        params: newParams,
        headers: authHeader(),
        data: {
          ids: store.state.roles.selectedItemsForDelete,
        },
      })
      .catch(e => {
        errorHandler(e)
        return e
      })
  }

  updatePermissions(role, permissions) {
    return $api
      .post(
        'permissions/sync',
        {
          role_id: role,
          permissions_ids: permissions,
        },
        {
          headers: authHeader(),
        }
      )
      .catch(e => {
        errorHandler(e)
        return e
      })
  }
}

export default new RolesService()
