<template>
  <div>
    <SidebarLeft :class="{'sidebar-left-show': $store.state.sidebars.leftSidebar}"></SidebarLeft>

    <TableContainer
      ref="table-container"
      :columns="tableData"
      :counter-filter="searchCount"
      :is-archive="$can('edit', 'role-read-archive')"
      :is-archiving="$can('edit', 'role-trash')"
      :is-filter="false"
      :is-loading-items="$store.state.other.isLoadingItems"
      :is-remove-from-archive="$can('edit', 'role-delete')"
      :rows="roles"
      :show-add-button="$can('edit', `role-create`)"
      :sorting-fields="sortingFields"
      :title="$t('Roles') || ''"
      is-archive
      name="roles"
      @show-right-sidebar="showRightSidebar"
      @on-selected="selectItem"
      @delete-item="deleteItem"
      @update-data="updateData"
      @archive-items="archiveElement"
      @return-from-archive="returnFromArchiveRoles"
    />

    <SidebarRight :class="{'sidebar-right--show': $store.state.sidebars.rightSidebar}">
      <RoleDetails
        ref="roleDetails"
        :current-item="currentItem"
        :loading-new-element="loadingNewElement"
        :new-element="newElement"
        @on-create="createElement"
        @on-update="updateElement"
      />
    </SidebarRight>
  </div>
</template>

<script>
import SidebarRight from '../components/SidebarRight'
import TableContainer from '@/components/TableContainer'
import SidebarLeft from '@/components/SidebarLeft'
import {eventBus} from '@/main'
import RolesService from '@/services/roles.service'
import {errorHandler} from '@/assets/scripts/scripts'
import RoleDetails from '@/components/RoleDetails.vue'

export default {
  components: {
    RoleDetails,
    TableContainer,
    SidebarLeft,
    SidebarRight,
  },
  data() {
    return {
      isLeftSidebarVisible: true,
      newElement: false,
      loadingNewElement: false,
      currentItem: {
        name: '',
        description: '',
        display_name: '',
      },
      directory: '',
      sortingFields: ['number', 'display_name', 2],
      tableData: [
        {
          label: 'id',
          field: 'number',
          sortField: 'number',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Название',
          field: 'display_name',
          sortField: 'display_name',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Описание',
          field: 'description',
          sortField: 'description',
          showInTable: false,
          sort: 0,
        },
      ],
      selectedItemId: '',
    }
  },

  computed: {
    searchCount() {
      return this.$store.state.roles.searchCount
    },
    roles() {
      return this.$store.state.roles.roles
    },
  },

  mounted() {
    const {id} = this.$route.params
    if (id) {
      this.selectedItemId = id
      this.$store.commit('roles/itemsForDelete', [id])
    }
    this.$store.commit('sidebars/changeLeftSidebar', false)
    this.$store.commit('sidebars/changeRightSidebar', id !== undefined)
    this.updateData()
  },
  methods: {
    async updateData() {
      RolesService.getRoles().then(r => {
        if (this.selectedItemId) {
          const data = r.data.data.find(el => el.id === this.selectedItemId)
          this.selectItem({
            column: {
              field: 'role',
            },
            row: data,
          })
        }
        this.$store.commit('roles/getRoles', r.data)
        eventBus.$emit('update-table-settings', r.data.meta.custom.settings.value.data)
        this.$store.commit('other/onChangeLoadingItems', false)
      })
    },
    createElement() {
      this.loadingNewElement = true
      RolesService.createRole(this.currentItem)
        .then(() => {
          this.loadingNewElement = false
          this.newElement = false
          this.updateData()
          if (this.$route.name !== 'roles') this.$router.push({name: 'roles'})
          this.$store.commit('sidebars/changeRightSidebar', false)
        })
        .catch(() => {
          this.loadingNewElement = false
        })
    },
    async updateElement() {
      this.loadingNewElement = true
      await RolesService.updateRole(this.currentItem.id, this.currentItem).then(() => {
        this.loadingNewElement = false
        this.$refs.roleDetails.edit = false
        this.updateData()
      })
      let userPermissions = []
      await this.$refs.roleDetails.schemaData.forEach(section => {
        section['containers'].forEach(container => {
          container.permissions.forEach(permission => {
            if (permission['is_assigned']) userPermissions.push(permission.id)
          })
        })
      })
      if (userPermissions.length > 0) await RolesService.updatePermissions(this.currentItem.id, userPermissions)
    },
    selectItem(data) {
      try {
        this.$refs.roleDetails.schemaData = []
        if (data.column.field !== 'checkbox') this.$store.commit('sidebars/changeRightSidebar', true)
        let updateData = data.row

        this.currentItem = updateData

        if (data.column.field !== 'checkbox')
          RolesService.getRole(updateData.id).then(r => {
            this.$refs.roleDetails.schemaData = r.data.data ? r.data.data['schemaPermissions'] : []
          })

        this.newElement = false
        if (this.selectedItemId !== data.row.id && data.column.field !== 'checkbox')
          this.$router.push({
            name: 'roles-id',
            params: {id: data.row.id},
          })
      } catch (e) {
        console.log(e)
      }
    },
    showRightSidebar() {
      this.$store.commit('sidebars/changeRightSidebar', true)
      this.$store.commit('roles/itemsForDelete', [])
      this.newElement = true
      this.currentItem = {
        name: '',
        description: '',
        display_name: '',
      }
      if (this.directory)
        this.directory.fields.forEach(el => {
          if (el.hasOwnProperty('type')) {
            if (el.type === 'list-objects') this.currentItem[el.field] = {data: []}
          } else {
            this.currentItem[el.field] = ''
          }
        })
    },
    archiveElement() {
      this.$store.commit('sidebars/changeRightSidebar', false)
      this.$store.state.roles.selectedItemsForDelete.forEach(() => {
        RolesService.deleteRole().then(r => {
          this.$toast.success(r.data['message'])
          if (this.$route.name !== 'roles') this.$router.push({name: 'roles'})
          RolesService.getRoles()
        })
      })
    },
    returnFromArchiveRoles() {
      RolesService.archiveRestoreRoles().then(r => {
        this.$toast.success(r.data.message)
        RolesService.getRoles()
      })
    },
    deleteItem() {
      this.$refs['table-container'].loadingDelete = true
      RolesService.deleteRole(true)
        .then(r => {
          if (this.$route.name !== 'roles') this.$router.push({name: 'roles'})
          this.$toast.success(r.data.message)
          this.$refs['table-container'].loadingDelete = false
          this.$refs['table-container'].showDeleteModal = false
          this.$store.commit('sidebars/changeRightSidebar', false)
          this.$store.commit('roles/itemsForDelete', [])
          eventBus.$emit('new-state', false)
          RolesService.getRoles()
        })
        .catch(e => {
          errorHandler(e)
        })
    },
  },
  beforeDestroy() {
    this.$store.commit('directory/getAllDirectoryItems', {
      data: [],
      meta: {
        custom: {
          total: null,
        },
        pagination: {
          current_page: null,
          total_pages: null,
          total: null,
        },
      },
    })
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  &__container {
    height: 100vh;
  }
}
</style>
